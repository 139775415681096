.LoginContainer {
  padding: 0 5.7rem;
  background-image: url("../../images/Common/karelabel-bg.jpg");
  /* background: linear-gradient(#352a54 0%, #ac13d5 100%); */
  min-height: 100vh;
}

h1.loginHeader {
  color: #ffffff;
  font-weight: normal;
  font-size: 2.5rem;
  padding-right: 23%;
  margin-bottom: 2.85rem;
}

.LoginFormContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoginWrapper {
  min-height: 100vh;
}

@media all and (max-width: 991px) {
  .LoginContainer {
    padding: 2rem;
  }
}

@media all and (max-width: 575px) {
  .LoginContainer {
    padding: 1rem;
  }
}
