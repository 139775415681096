.HeaderWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.HeaderWrapper > div {
  display: flex;
  align-items: center;
}
.StoreNameWrap {
  margin-left: 15px;
  color: #352a54;
}
.LogoWrapper {
  width: 176px;
  margin-left: 15px;
  position: relative;
  margin-right: 20px;
}
.LogoWrapper:after {
  content: "";
  position: absolute;
  width: 1px;
  background: #d2d2d2;
  display: block;
  right: -19px;
  height: 60%;
  top: 20%;
}
.Seperator {
  width: 13px;
  height: 13px;
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
.ButtonIcon {
  margin-right: 5px;
}
.HeaderRightSide {
  display: flex;
  align-items: center;
}
.HeaderRightSide > *:not(:last-child) {
  margin-right: 49px;
}
.SearchInput {
  min-width: 250px;
}
.SearchInput :global .ant-input-search-icon::before {
  display: none;
}
.ProfileHolder {
  background-color: #352a54;
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-right: 13px;
  height: 54px;
  margin-bottom: 10px;
  border-radius: 0 0 0 27px;
  color: #ffffff;
}
.ProfileImage {
  height: 29px;
  width: 29px;
  object-fit: cover;
  border-radius: 100%;
  margin-right: 10px;
}
.UserName {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 2px;
  white-space: nowrap;
  max-width: 30vw;
  text-overflow: ellipsis;
  overflow: hidden;
}
.UserInitials,
.DesignationInitials {
  display: none;
}
.UserDesignation {
  font-size: 12px;
  white-space: nowrap;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.LogoutIcon {
  color: #fe1d62;
  margin-left: 10px;
}
.passwordIcon {
  color: #fcc350;
  margin-left: 10px;
}
.LogoutIcon:hover,
.LogoutIcon:focus {
  color: #c9095c;
}
.passwordIcon:hover,
.passwordIcon:focus {
  color: #ecac2c;
}
.CreateOrderButton {
  padding-left: 37px;
  padding-right: 37px;
}

@media all and (max-width: 1200px) {
  .HeaderRightSide > *:not(:last-child) {
    margin-right: 25px;
  }
  .SearchInput {
    min-width: 0px;
  }
  .CreateOrderButton {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media all and (max-width: 991px) {
  .HeaderRightSide > *:not(:last-child) {
    margin-right: 15px;
  }
}
@media all and (max-width: 800px) {
  .FullUserName,
  .FullDesignation {
    display: none;
  }
  .UserInitials,
  .DesignationInitials {
    display: block;
  }
}
