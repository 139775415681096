table {border-collapse: collapse;}
table td {padding: 0px}
@font-face {
    font-family: ff2;
    src: url("../Fonts/myriadpro-semibold.otf") format("opentype");
}.ff2{
    font-family:ff2;
    line-height:0.931000;font-style:normal;font-weight:normal;visibility:visible;}

@font-face {
    font-family: ff1;
    src: url("../Fonts/myriadpro-regular.otf") format("opentype");
}.ff1{
    font-family:ff1;
    line-height:0.919000;font-style:normal;font-weight:normal;visibility:visible;}
g text{
    font: 9pt monospace !important;
}