.SidebarIcon {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}
:global(.ant-menu-inline-collapsed) .SidebarIcon {
  margin-right: 25px;
}
.MenuToggler {
  width: 20px;
  height: 20px;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: -11px;
  padding: 0;
  line-height: 20px;
  border-radius: 100%;
}
.MenuToggler:hover,
.MenuToggler:focus {
  background-color: #352a54;
  border-color: #352a54;
}
.TogglerIconOpen {
  transform: rotate(180deg);
}
.SidebarMenu {
  margin-top: 30px;
}
