.CancelButton {
  margin-right: 1.8rem;
}
.ButtonContainer {
  text-align: right;
}
.DeleteButton {
  text-align: right;
}
.maps {
  width: 100%;
  height: 300px;
}
.UserKYCHeader,
.UserRoleHeader {
  margin-bottom: 5px;
}

h4.UserKYCTitle,
h4.UserRoleTitle {
  font-size: 18px;
  margin-bottom: 0;
}
.AddKYCForm {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.ActionsRow {
  margin-top: auto;
}
.AddKYCFormHeader {
  margin-bottom: 20px;
}
.KYCButtonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
