.TableActionWrapper :global(.ant-space-item) {
  margin-bottom: 8px;
}
.TableActionWrapperWPagination {
  max-width: 50%;
  margin-top: -47px;
  margin-bottom: 47px;
}
.TableActionWrapper :global(.ant-space) {
  flex-wrap: wrap;
}
.TableActionWrapperWoPagination {
  margin-top: 15px;
}
