.AttendanceOptionsWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}
.AttendanceOption {
  min-width: 80px;
  text-align: center;
}
.AttendanceOptionsWrap
  :global(.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child),
.AttendanceOptionsWrap
  :global(.ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)),
.AttendanceOptionsWrap
  :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before) {
  background-color: #352a54;
  border-color: #352a54;
}

.ButtonAndCountWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
}
.ButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ButtonWrapper > button {
  margin-bottom: 10px;
}
.inButton,
.outButton {
  min-width: 100px;
}
.outButton {
  background-color: #cf1e1e;
  border-color: #cf1e1e;
}
.outButton:focus,
.outButton:hover {
  background-color: #b81111;
  border-color: #b81111;
}
.inButton {
  background-color: #378f1c;
  border-color: #378f1c;
}
.inButton:focus,
.inButton:hover {
  background-color: #2a7913;
  border-color: #2a7913;
}
.countWrap {
  margin: 20px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px dashed #cccccc;
  font-size: 70px;
  line-height: 120px;
  text-align: center;
  font-weight: 500;
  margin-left: 150px;
}
.info {
  max-width: 50%;
  text-align: center;
  margin: 10px auto;
}
