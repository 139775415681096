.CustomTransferContainer {
  position: relative;
}
.CustomTransfer {
  justify-content: space-between;
}
.CustomTransfer > :global(.ant-transfer-list) {
  width: 33%;
  height: calc(100vh - 190px);
  min-height: 420px;
  max-height: 100%;
}

.CustomTransfer > :global(.ant-transfer-operation) {
  width: 34%;
  align-self: flex-end;
  text-align: center;
  align-items: center;
  padding-bottom: 45px;
  padding-top: 300px;
}
.CustomTransfer :global(.ant-transfer-list-header-selected) {
  order: 2;
}
.CustomTransfer :global(.ant-transfer-list-header-title) {
  font-weight: bold;
  text-align: left;
}
.MiddleColumn {
  width: 34%;
  position: absolute;
  top: 0;
  left: 33%;
  padding: 0 40px;
}
.SingleItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.SingleItem img {
  width: 15px;
  opacity: 0.8;
}
.CustomTransfer :global(.ant-transfer-operation .ant-btn) {
  border-radius: 100px;
  width: 35px;
  height: 35px;
}
.CustomTransfer
  :global(.ant-transfer-operation .ant-btn-primary:not(:disabled)) {
  background-color: #fe1d7f;
  border-color: #fe1d7f;
}

.CustomTransfer
  :global(.ant-transfer-operation .ant-btn-primary:not(:disabled):hover) {
  background-color: #bb0554;
  border-color: #bb0554;
}

.SearchStatus {
  color: #7e13d5;
  border: 1px solid #7e13d5;
  min-height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
  animation: pulsating 2s infinite;
}
.SearchStatus > span {
  position: relative;
  z-index: 1;
}
.SearchStatus:before,
.SearchStatus:after {
  content: "";
  display: block;
  position: absolute;
  width: calc(100% - 50px);
  left: 25px;
  top: -1px;
  height: calc(100% + 2px);
  background-color: #fff;
  z-index: 0;
}
.ScannedItem {
  background-color: #6abeff;
  border-radius: 10px;
  padding: 15px;
  color: #fff;
}

.SearchStatus:after {
  width: calc(100% + 2px);
  left: -1px;
  height: calc(100% - 30px);
  top: 15px;
}
.ScannedItemHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.ScannedItemLabel {
  color: #000;
}
.ScannedItemTag {
  font-size: 16px;
  font-weight: bold;
}
.ScannedItemName {
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
}
.ScannedItemOrder {
  margin-bottom: 10px;
}
.ScannedItemOrder > span,
.ScannedItemCustomer > span {
  display: inline-flex;
  align-items: center;
}
.InfoIcon {
  filter: brightness(10);
  width: 12px;
  margin-left: 10px;
  cursor: pointer;
}
.SearchInput :global(.ant-input-search-icon::before) {
  display: none;
}

.CustomTransfer :global(.ant-transfer-operation .ant-btn:last-child) {
  display: none;
}
.CustomTransfer :global(.ant-transfer-list-header .anticon svg:last-child) {
  display: none;
}

.CustomTransfer:global(.anticon svg) {
    display: contents;
}


@keyframes pulsating {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
