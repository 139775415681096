.tags-table td {
  font-family: "Impact, fantasy";
  color: #000000;
}

/* @media all {
  .pagecut {
    display: none;
  }
} */

@media print {
  html,
  body {
    /* height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact; */
    font-family: "Impact, fantasy";
    color: #000000;
  }
}

/* @media print {
  .pagecut {
    margin-top: 1rem;
    display: block;
    page-break-before: page;
  }
} */

/* @page {
  size: auto;
  margin: 20mm;
} */
