.StepsContentWrap {
    padding-top: 30px;
    display: grid;
    grid-template-columns: 30fr 70fr;
  }
  .StepsContent {
    padding-left: 30px;
    padding-right: 15px;
  }
  .StepsWrapper {
    padding-left: 2rem;
    border-right: 1px solid #c6c6c6;
  }
  
  @media all and (max-width: 991px) {
    .StepsContentWrap {
      grid-template-columns: auto;
      grid-template-rows: auto;
    }
    .StepsWrapper {
      padding-left: 0;
      border-right: none;
      margin-bottom: 2rem;
      border-bottom: 1px solid #c6c6c6;
    }
    .StepsContent {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  