.SearchInput:global(.ant-input-affix-wrapper) {
    padding: 0;
    padding-left: 14px;
  }
  .SearchInput :global .ant-input-search-icon::before {
    display: none;
  }
  .FilterToggle {
    color: #666666;
    min-width: 95px;
  }
  .FilterToggle:hover {
    color: #000000;
  }
  .FilterToggleIcon {
    margin-left: 5px;
    transition-duration: 0.3s;
  }
  .FilterToggleIconRotated {
    margin-left: 5px;
    transform: rotate(180deg);
    transition-duration: 0.3s;
  }
  .ActionContainer {
    text-align: right;
  }
  
