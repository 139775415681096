.StatsWrapper {
  border-radius: 5px;
  padding: 10px 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  line-height: 1.25;
  margin-bottom: 25px;
  /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3); */
  background-color: #f3f6f9;
}
.StatsWrapper .StatsTitle {
  font-size: 14px;
  font-weight: bold;
  color: #666666;
}
.StatsWrapper .StatsCount {
  font-size: 20px;
  color: #7e13d5;
}
.StatsWrapper .StatsIcon {
  border-radius: 100%;
  border: 1px solid #7e13d5;
  height: 50px;
  width: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
