

.Title{
  /* margin-left: 100px; */
  flex: 1;
  display: flex;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  color:black;
  
}

.factory{
  width: 32%;
  padding: 5px;
  /* flex: 1; */
  /* display: flex; */
  /* width: 305px; */
  /* border-radius: 10px; */
  /* margin-left: 5px; */
  /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); */
  /* borderRadius: 10; */
  /* marginLeft: 5; */ 
  /* boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)"; */

}

.PostProcess{
  width: 32%;
  border-left: 2px solid black;
  border-right: 2px solid black;
  padding: 5px;
  /* flex: 1; */
  /* display: flex; */
  /* width: 305px; */
  /* border-radius: 10px;
  margin-right:15px ;
  margin-left: 15px; */
  /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); */
}

.PaymentStatus{
  width: 32%;
  padding: 5px;
  /* flex: 1; */
  /* display: flex; */
  /* width: 305px; */
  /* border-radius: 10px; */
  /* margin-right: 20px; */
  /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); */
                     
}





















 



