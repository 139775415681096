.BtnDarkPurple {
  background-color: #352a54;
  color: #ffffff;
  border-color: #352a54;
}
.BtnDarkPurple:hover,
.BtnDarkPurple:focus {
  background-color: #7e13d5;
  border-color: #7e13d5;
  color: #ffffff;
}
.BtnTransparentGreyOutline {
  border-color: #c6c6c6;
  background-color: transparent;
  color: #666666;
}
.BtnTransparentGreyOutline:hover,
.BtnTransparentGreyOutline:focus {
  border-color: #fe1d7f;
  color: #fe1d7f;
}
.BtnPurple {
  background-color: #7e13d5;
  border-color: #7e13d5;
  color: #ffffff;
}
.BtnPurple:hover,
.BtnPurple:focus {
  background-color: #352a54;
  border-color: #352a54;
  color: #ffffff;
}
.BtnLink {
  color: #7e13d5;
  padding: 0;
}
.BtnLink:hover,
.BtnLink:focus {
  color: #352a54;
}
:global(.ant-table-cell) .BtnLink {
  color: #666666;
}
:global(.ant-table-cell) .BtnLink:hover,
:global(.ant-table-cell) .BtnLink:focus {
  color: #7e13d5;
}
.BtnPurpleOutline {
  border-color: #7e13d5;
  color: #7e13d5;
}
.BtnPurpleOutline:hover,
.BtnPurpleOutline:focus {
  background-color: #7e13d5;
  color: #ffffff;
  border-color: #7e13d5;
}

.BtnWhitePink {
  background-color: #ffffff;
  color: #fe1d7f;
  border-color: #ffffff;
}

.BtnWhitePink:hover,
.BtnWhitePink:focus {
  background-color: #bb0554;
  border-color: #bb0554;
  color: #ffffff;
}

.BtnPink {
  background-color: #fe1d7f;
  border-color: #fe1d7f;
  color: #ffffff;
}
.BtnPink:hover,
.BtnPink:focus {
  background-color: #bb0554;
  border-color: #bb0554;
  color: #ffffff;
}
:global(.ant-skeleton-element)
  .SkeletonButtonBlock:global(.ant-skeleton-button-lg) {
  width: 100%;
}
