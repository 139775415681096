.CustomerName {
  font-size: 26px;
  color: #666666;
  font-weight: bold;
}
.WalletBalance {
  color: #fe1d7f;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 3px;
}
.WalletBalance > span {
  margin-left: 10px;
}
.OrderNumber {
  font-size: 16px;
  color: #666666;
  font-weight: bold;
}
.TextRightCol {
  text-align: right;
  padding-top: 5px;
}
.RightSideContainer {
  border-left: 1px solid #c6c6c6;
  height: 100%;
  padding-left: 50px;
  padding-right: 35px;
}
.InvoiceWrapper {
  padding-left: 15px;
}
.StickyWrapper {
  padding-top: 30px;
  padding-bottom: 30px;
  position: sticky;
  top: 0px;
}
.TotalWrap {
  display: flex;
  align-items: center;
  font-size: 20px;
  justify-content: space-between;
}
.TotalWrap .TotalValue {
  font-size: 24px;
  font-weight: bold;
}
.PromocodeInput:global(.ant-input-affix-wrapper-focused),
.PromocodeInput:global(.ant-input-affix-wrapper:hover) {
  border-color: #fe1d7f;
}
.PromocodeInput:global(.ant-input-affix-wrapper-focused) {
  box-shadow: 0 0 0 2px rgba(254, 29, 126, 0.18);
}
.PromocodeInput:global(.ant-input-affix-wrapper) {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
}
.PromocodeInput :global(.ant-input-suffix .ant-btn) {
  border-radius: 4px;
}
.PromocodeInput :global(.ant-input-prefix) {
  color: #fe1d7f;
}
.ButtonsWrap {
  width: 100%;
}
.ButtonsWrap button:global(.ant-btn) {
  /* padding: 11px 15px; */
  font-size: 20px;
  /* font-weight: bold; */
}
.ButtonsWrap button:global(.ant-btn) img,
.ButtonsWrap button:global(.ant-btn) span {
  margin-left: 5px;
  margin-right: 5px;
}
.OrderSummaryContainer {
  background-color: #ffffff;
  border-radius: 5px 0px 0px 5px;
  padding: 20px;
  width: calc(100% + 25px);
  margin-right: -25px;
}
.OrderTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  position: relative;
  padding-bottom: 7px;
  margin-bottom: 7px;
}
.OrderTitle:after,
.SummaryTableWrapper:after {
  content: "";
  width: calc(100% + 40px);
  height: 2px;
  border-bottom: 1px dashed #b8b8b8;
  position: absolute;
  bottom: 0;
  left: -20px;
}
.SummaryTable {
  width: 100%;
  margin-bottom: 15px;
}
.SummaryTableWrapper {
  position: relative;
  padding-bottom: 35px;
}
.ItemRow > td {
  background-color: #fafafa;
  border-bottom: 1px solid #eeeeee;
}
.CategoryLabel {
  font-size: 13px;
  font-weight: bold;
  padding: 2px 9px;
  background-color: #ff8eb1;
  display: inline-block;
  border-radius: 5px;
  color: #ffffff;
  margin-top: 15px;
}
.PromocodeInput {
  margin-top: 10px;
  /* margin-bottom: 7px; */
}
.PromocodeInput:global(.ant-input-affix-wrapper-focused),
.PromocodeInput:global(.ant-input-affix-wrapper:hover) {
  border-color: #fe1d7f;
}
.PromocodeInput:global(.ant-input-affix-wrapper-focused) {
  box-shadow: 0 0 0 2px rgba(254, 29, 126, 0.18);
}
.PromocodeInput:global(.ant-input-affix-wrapper) {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
}
.PromocodeInput :global(.ant-input-suffix .ant-btn) {
  border-radius: 4px;
}
.PromocodeInput :global(.ant-input-prefix) {
  color: #fe1d7f;
}
.ItemSettingIcon {
  color: #c6c6c6;
  margin-left: 10px;
  border: none;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  cursor: pointer;
}
.ItemSettingIcon:hover,
.ItemSettingIcon:focus {
  outline: none;
  color: #666;
}
.SummaryInfoWrap {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  color: #707070;
}
.SummaryInfoLabel,
.SummaryInfoLabel > .InfoIcon {
  display: flex;
  align-items: center;
}
.SummaryInfoLabel > .InfoIcon img {
  margin-left: 5px;
  width: 14px;
  cursor: pointer;
}
.SummaryInfoValue {
  white-space: nowrap;
}
.SummaryInfoSubPart .SummaryInfoWrap {
  color: #ababab;
}
.TotalBlock {
  background-color: #352a54;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  font-weight: bold;
  padding: 8px 20px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.ReviewOrderButton {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  padding: 10px 26px;
  margin-bottom: 30px;
}
.ReviewOrderButton > div {
  display: flex;
  justify-content: space-between;
}

/**
Styling for Invoice
@added 11 June 2020
*/
.SummaryContainerInvoice {
  margin-top: 10px;
  padding: 0;
  width: 100%;
}
.SummaryTableInvoice > table {
  border: 1px solid #eeeeee;
}
.SummaryTableInvoice > table td,
.SummaryTableInvoice > table th {
  padding: 3px 14px;
}
.SummaryTableInvoice > table th {
  background-color: #fafafa;
  border-bottom: 1px solid #eeeeee;
}
.SummaryTableInvoice .CategoryLabel {
  margin-top: 0;
}
.SummaryTableInvoice:after {
  display: none;
}
.AdhocAmountWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
