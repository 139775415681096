.ChartsContainer {
  padding-bottom: 28px;
}
.ChartsWrapper :global(h4.ant-typography) {
  font-size: 16px;
  font-weight: bold;
  color: #666666;
}
.ChartsTitleWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ChartsTitleWrap
  :global(.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector) {
  padding: 2px 10px;
}
