.Box {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.04);
  min-height: calc(100vh - 133px);
  padding: 15px;
}

.SubBox {
  min-height: 1px;
  height: 100%;
}
