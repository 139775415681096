.fingerPrint {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.fingerPrintWrap {
  border: 1px dashed #cccccc;
  width: 120px;
  height: 120px;
  padding: 10px;
  margin: 5px;
}
.fingerPrintContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fingerPrintBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
