.StatsWrapper {
  border-radius: 5px;
  padding: 15px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  line-height: 1.25;
  margin-bottom: 25px;
}
.StatsWrapper .StatsTitle {
  font-size: 13px;
  font-weight: bold;
}
.StatsWrapper .StatsCount {
  font-size: 36px;
}
.StatsWrapper .StatsIcon {
  border-radius: 100%;
  border: 2px solid #ffffff;
  height: 60px;
  width: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
