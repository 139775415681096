.WrapLogo {
  background-color: #261159;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem 3.5rem;
  border-radius: 10px;
  width: 28vw;
  max-width: 485px;
  margin-top: -26px;
}

.LoginFormWrapper {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3rem 5.5rem;
  border-radius: 10px;
  width: 30vw;
  max-width: 485px;
}
.LoginButton {
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
}

.BackButton {
  align-items: left;
  margin-right: auto;
  margin-left: 0;
  margin-top: 15px;
}
.ForgetPassswordLink {
  text-align: center;
}
.ForgetPassswordLink a {
  color: #666666;
}
.ForgetPassswordLink a:hover {
  color: #333333;
}
.LoginFromEl {
  width: 100%;
  margin-top: 2.5rem;
}

@media all and (max-width: 1200px) {
  .LoginFormWrapper {
    padding: 2rem 3.5rem;
  }
}

@media all and (max-width: 991px) {
  .LoginFormWrapper {
    width: 100%;
  }
}
@media all and (max-width: 767px) {
  .LoginFormWrapper {
    padding: 2rem;
  }
}
