h4.SlotMappingTitle {
  font-size: 18px;
  margin-bottom: 0;
}
.AddSlotForm {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.ActionsRow {
  margin-top: auto;
}
.AddSlotFormHeader {
  margin-bottom: 20px;
}
.ButtonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.CancelButton {
  margin-right: 1.8rem;
}
.ButtonContainer {
  text-align: right;
}
.maps {
  width: 100%;
  height: 300px;
}

.SlotMappingHeader {
  margin-bottom: 5px;
}
h4.SlotMappingTitle {
  font-size: 18px;
  margin-bottom: 0;
}
