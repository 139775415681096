/*************************************************/
/*      Table of Contents                        */
/*                                               */
/*    1. Common                                  */
/*    2. Library Global Overriding               */
/*    3. Custom Scrollbar                        */
/*                                               */
/*                                               */
/*                                               */
/*************************************************/

/* Start :: Common */
html,
body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
a,
.ant-radio-button-wrapper:hover {
  color: #7e13d5;
}
a:hover {
  color: #352a54;
}
.text-right {
  text-align: right;
}
.w-100 {
  width: 100%;
}
.W-90{
  width: 90%;
}
.text-nowrap {
  white-space: nowrap;
}
.anticon-barcode ~ .ant-input-search-icon {
  display: none;
}
.ant-input-search .anticon-barcode {
  margin-right: 10px;
}
/* End :: Common */

/* Start :: Library Global Overriding */
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow: initial;
}
.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  min-height: 0;
}
.ant-input-affix-wrapper,
.ant-input,
.ant-picker,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-multiple .ant-select-selector {
  padding: 7px 14px;
  border-radius: 5px;
  width: 100%;
  height: auto;
}
.ant-input-number:focus,
.ant-input-number-focused,
.ant-input-number {
  border-radius: 5px;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 1.5715;
}

.ant-select-multiple .ant-select-selector::after {
  line-height: 24px;
}
.ant-descriptions-title {
  margin-bottom: 5px;
}
.ant-descriptions {
  margin-bottom: 24px;
}
.ant-input-affix-wrapper:hover,
.ant-input:hover,
.ant-picker:hover,
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #7e13d5;
}
.ant-input-affix-wrapper-focused,
.ant-input:focus,
.ant-input-focused,
.ant-picker-focused,
.ant-radio-input:focus + .ant-radio-inner,
.ant-select-focused.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-select-focused.ant-select-multiple .ant-select-selector,
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: #7e13d5;
  box-shadow: 0 0 0 2px rgba(126, 19, 213, 0.18);
}
.ant-input-prefix {
  color: #666666;
  margin-right: 0.8rem;
}
.ant-form-vertical .ant-form-item-label {
  padding-bottom: 5px;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-radio-checked .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-checked::after,
.ant-checkbox-checked::after,
.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:hover,
.ant-btn-primary,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-input-number:hover,
.ant-modal-confirm-btns .ant-btn:hover,
.ant-modal-confirm-btns .ant-btn:focus {
  border-color: #7e13d5;
}
.ant-picker-today-btn,
.ant-picker-header-view button:hover,
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-btn:hover,
.ant-btn:focus {
  color: #7e13d5;
  font-weight: normal;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #ffffff;
  border-color: #352a54;
  background-color: #352a54;
}
.ant-input-number-handler-up {
  border-top-right-radius: 5px;
}
.ant-input-number-handler-down {
  border-bottom-right-radius: 5px;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-radio-inner::after,
.ant-checkbox-checked .ant-checkbox-inner,
.ant-btn-primary,
.ant-checkbox-indeterminate .ant-checkbox-inner::after,
.ant-picker-range .ant-picker-active-bar,
.ant-spin-dot-item {
  background-color: #7e13d5;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner,
.ant-table-tbody > tr.ant-table-row-selected > td,
.ant-transfer-list-content-item-checked,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background-color: #ebd3ff;
}
.ant-table-tbody > tr.ant-table-row-selected:hover > td,
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover,
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  background-color: #e1bffd;
}
.ant-select-multiple .ant-select-selection-item {
  background: #ebd3ff;
  border-radius: 18px;
  border-color: #ebd3ff;
}
.ant-picker-today-btn:hover,
.ant-tabs-tab:hover,
.ant-tabs-tab:focus,
.ant-tabs-tab:active {
  color: #352a54;
}

.ant-select-multiple .ant-select-selector {
  padding: 4px 11px;
}
.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100%;
}
.ant-select.ant-select-show-search.ant-select-multiple
  .ant-select-selector
  input {
  margin-left: 0;
}
.ant-btn {
  height: auto;
  border-radius: 50px;
}
.ant-btn-round {
  border-radius: 100px;
}
.ant-layout {
  background-color: #f3f3f3;
}
.ant-page-header-back-button:hover,
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #7e13d5;
}
.ant-page-header-back-button:active,
.ant-page-header-back-button:focus {
  color: #352a54;
}
.ant-page-header-back-button,
.ant-page-header-heading-title {
  font-size: 22px;
  color: #666666;
  font-weight: bold;
}
.ant-page-header {
  padding: 0;
}
.ant-steps-item {
  padding-bottom: 2rem;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fe1d7f;
  border-color: #fe1d7f;
}

.ant-popover-buttons .ant-btn:not(.ant-btn-primary):hover,
.ant-popover-buttons .ant-btn:not(.ant-btn-primary):focus {
  color: #fe1d7f;
  border-color: #fe1d7f;
}
.ant-btn-primary:hover {
  background-color: #650cad;
  border-color: #650cad;
  color: #fff;
}
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title,
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #fe1d7f;
}
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  font-weight: bold;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after,
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #fe1d7f;
}
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: rgba(254, 29, 127, 0.65);
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #fe1d7f;
}
.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  padding: 32px 0px 0px;
}
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-icon
  .ant-steps-icon,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-title,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-description,
.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination-item:focus a,
.ant-pagination-item:hover a,
.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: #7e13d5;
}
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-icon,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  border-color: #7e13d5;
}
.ant-form-item {
  color: #5d5d5d;
}
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #c6c6c6;
}
.ant-layout-sider {
  min-height: calc(100vh - 64px);
  background-color: #352a54;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-inline.ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-inline.ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background-color: #352a54;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
.ant-menu.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-item > span {
  opacity: 1;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item
  a.active:before,
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-submenu
  a.active:before {
  content: "";
  background-color: #09d1ff;
  opacity: 0.4;
  position: absolute;
  width: calc(100% - 20px);
  height: 100%;
  left: 10px;
  top: 0;
  border-radius: 6px;
  pointer-events: none;
}
.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item
  a.active:before {
  width: 100%;
  left: 0;
  border-radius: 0;
}
.ant-menu-inline-collapsed.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item
  a.active:before,
.ant-menu-inline-collapsed.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-submenu
  a.active:before {
  width: calc(100% + 20px);
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-item,
.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-submenu-title,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-dark.ant-menu-vertical-collapsed .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-collapsed .ant-menu-submenu-title {
  line-height: 1;
  height: auto;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 34px;
  margin: 0;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-submenu
  .ant-menu-vertical
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  margin: 0;
  height: 28px;
  line-height: 28px;
}
.ant-menu-submenu-title > span > a,
.ant-menu-vertical .ant-menu-submenu-selected a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: #fff;
}
.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-item > span {
  opacity: 0;
  max-width: 0;
}
.ant-menu-inline-collapsed.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected:before {
  width: calc(100% + 20px);
}
.ant-layout-header {
  background-color: #fff;
  padding: 0px;
  line-height: 1;
}
.ant-badge-count {
  background-color: #fe1d7f;
}
.ant-page-header-heading-extra {
  display: flex;
}
.ant-table-pagination.ant-pagination {
  display: flex;
}
.ant-table-pagination.ant-pagination > li {
  order: 2;
}
.ant-table-pagination.ant-pagination .ant-pagination-options {
  order: 1;
}
.ant-table-pagination.ant-pagination
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item,
.ant-table-pagination.ant-pagination
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 28px;
}
.ant-table-pagination.ant-pagination
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 28px;
}
.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next,
.ant-pagination.mini .ant-pagination-item {
  height: 28px;
  width: 28px;
  line-height: 24px;
  border: 1px solid #c6c6c6;
  border-radius: 100px;
  margin-left: 2px;
  margin-right: 2px;
}
.ant-pagination.mini .ant-pagination-item {
  line-height: 27px;
}
.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  border: 1px solid #c6c6c6;
}
.ant-pagination.mini .ant-pagination-item.ant-pagination-item-active {
  background-color: #7e13d5;
  border-color: #7e13d5;
  color: #fff;
}
.ant-pagination.mini .ant-pagination-item.ant-pagination-item-active a {
  color: #fff;
}
.ant-pagination-item a {
  padding: 0;
}
.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 6px;
}
.modal-form .ant-modal-content {
  border-radius: 10px;
}
.modal-form .ant-modal-header {
  border-radius: 10px 10px 0 0;
  border-bottom: 3px solid #7e13d5;
  background: #fafafa;
}
.modal-form .ant-modal-body {
  padding-bottom: 17px;
}
.modal-form .custom-modal-footer {
  padding-top: 17px;
  text-align: right;
}
.modal-form .custom-modal-footer:before {
  border-top: 1px solid #f0f0f0;
  height: 1px;
  width: calc(100% + 24px);
  content: "";
  display: block;
  top: 0;
  left: -12px;
  position: absolute;
}
.ant-tag {
  border-radius: 100px;
  padding: 1px 13px;
}
.ant-form-item-label > label.ant-form-item-required::before {
  order: 2;
  margin-right: 0;
  margin-left: 4px;
}
.ant-table-cell .ant-space .ant-space-item a {
  color: inherit;
}
.ant-table-cell .ant-space .ant-space-item a:hover,
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #7e13d5;
}
.ant-table-cell .ant-space .ant-space-item a,
.ant-table-cell .ant-space .ant-space-item button {
  cursor: pointer;
}
.ant-form-inline button {
  min-height: 38px;
}
.ant-table-cell > .ant-select {
  width: 100%;
}
.ant-select-sm.ant-select-multiple .ant-select-selector {
  padding: 4px;
}
.ant-table-cell
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 4px 7px;
  height: 30px;
}
@media all and (max-width: 991px) {
  .ant-steps-item-description {
    display: none;
  }
}
/* End :: Library Global Overriding */

/* Start :: Custom Scrollbar */
body *::-webkit-scrollbar {
  width: 5px;
}

body *::-webkit-scrollbar-track {
  background-color: #e3e6e9;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

body *::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d;
}
/* End :: Custom Scrollbar */
/* Start :: Chart Library Customization */
.recharts-layer tspan,
.recharts-legend-item {
  font-size: 12px;
}
.recharts-label tspan {
  font-size: 10px;
  color: #666;
}

.ant-spin {
  /* background-image: url("./images/loader.png");
  background-size: 60px;
  background-repeat: no-repeat;*/
  background-color: #ffffff;
  /*background-position: center center;
  transition: all 0.3s ease; */
  cursor: wait;
  /* min-height: 120px; */
}
.ant-spin-dot {
  width: 60px;
  height: 60px;
  background-image: url("./images/loader.png");
  background-size: 60px;
  background-repeat: no-repeat;
  background-color: #ffffff;
  background-position: center center;
  transition: all 0.3s ease;
}
.ant-spin-dot .ant-spin-dot-item {
  display: none;
}
.review-order-table .ant-upload-list-item-name {
  width: 80px;
}
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  max-height: 100px;
  overflow: auto;
}
.ant-table-container {
  width: calc(100% - 20px);
  overflow-x: auto;
}
.ant-layout-content {
  width: calc(100% - 250px);
}

.lan2 .ant-col-xs-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.lan2 .ant-col-xs-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.ant-transfer-list:first-child
  .ant-transfer-list-content-item:not(:disabled):not(.disabled) {
  pointer-events: none;
}

.ant-transfer-list:first-child .ant-transfer-list-content-item * {
  pointer-events: none;
}

.ant-transfer-list:first-child
  .ant-transfer-list-content-item
  .ant-checkbox-wrapper {
  display: none;
}

.ant-transfer-list:first-child .ant-transfer-list-header .ant-checkbox-wrapper {
  pointer-events: none;
  display: none;
}
.ant-transfer-operation {
  display: none;
}

.ant-transfer-list:first-child .ant-transfer-list-content-item img {
  pointer-events: auto;
}

.ant-list-item-meta-content {
  white-space: pre-line;
  word-break: break-word;
}


.pickupslot .ant-modal-content .ant-modal-body{
  padding: 5px  ;
}

.pickupslot { 
  width : 60% !important; 
  top: 5% !important; 
}
.pickupslot .mapview { 
  min-height: 430px;
}
.pickupslot .mapview #map { 
  height: 100%;
}

.viewMapBtn {
  align-self: center;
  width: auto;
  height: 40px;
}
