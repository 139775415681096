.PrintContainer {
  text-align: right;
  display: flex;
  margin-left: 200px;
  justify-content: space-evenly;
}

.FilterToggle {
  color: #666666;
  min-width: 95px;
}
.FilterToggle:hover {
  color: #000000;
}
.FilterToggleIcon {
  margin-left: 5px;
  transition-duration: 0.3s;
}
.FilterToggleIconRotated {
  margin-left: 5px;
  transform: rotate(180deg);
  transition-duration: 0.3s;
}

.ActionContainer {
  text-align: right;
}

.RewashPrintContainer {
  text-align: right;
  display: flex;
  margin-left: 200px;
  justify-content: flex-end;
}

.dataTable{
  text-align: center;
}