.SearchInput:global(.ant-input-affix-wrapper) {
  padding: 0;
  padding-left: 14px;
  height: 36px;
}
.SearchInput :global .ant-input-search-icon::before {
  display: none;
}
.FilterToggle {
  color: #666666;
  min-width: 95px;
}
.FilterToggle:hover {
  color: #000000;
}
.FilterToggleIcon {
  margin-left: 5px;
  transition-duration: 0.3s;
}
.FilterToggleIconRotated {
  margin-left: 5px;
  transform: rotate(180deg);
  transition-duration: 0.3s;
}
.ActionContainer {
  text-align: right;
}
.SubHeader {
  margin-bottom: 15px;
}
.StepsContentWrap {
  padding-top: 30px;
  display: grid;
  grid-template-columns: 30fr 70fr;
}
.StepsContent {
  padding-left: 30px;
  padding-right: 15px;
}
.StepsContent :global(.ant-select-selection-item) {
  white-space: normal;
}
.StepsWrapper {
  padding-left: 2rem;
  border-right: 1px solid #c6c6c6;
}
.VerificationWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.VerificationWrapper > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}
/* .VerificationWrapper > div:first-child {
    border-right: 1px solid #eee;
  } */
.VerificationContainer,
.VerificationContainer :global(.ant-spin-nested-loading),
.VerificationContainer :global(.ant-spin-container) {
  height: 100%;
}
.ResendLink {
  margin-top: 10px;
  color: #999;
}
.CancelButton {
  margin-right: 1.8rem;
}
.ButtonContainer {
  text-align: right;
}
.advanceAmountInput :global(.ant-input-number),
.advanceAmountInput :global(.ant-input-number-input-wrap) {
  width: 100%;
  min-height: 37px;
  height: 37px;
  line-height: 37px;
}
@media all and (max-width: 991px) {
  .StepsContentWrap {
    grid-template-columns: auto;
    grid-template-rows: auto;
  }
  .StepsWrapper {
    padding-left: 0;
    border-right: none;
    margin-bottom: 2rem;
    border-bottom: 1px solid #c6c6c6;
  }
  .StepsContent {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.ant-layout-content {
  width: calc(100% - 250px);
}

.maps {
  width: 100%;
  height: 300px;
}

/* .disabledrow {
  background-color: #ec4242;
  color: aqua;
  pointer-events: none;
} */
/* 
.lan2 .ant-col-xs-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.lan2 .ant-col-xs-6 {
  flex: 0 0 50%;
  max-width: 50%;
} */