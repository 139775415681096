.packageWrapper {
  border-radius: 5px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  padding: 50px 30px;
  /* color: #ffffff; */
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.bonus {
  font-size: 18px;
}
.desc {
  font-size: 14px;
  line-height: 16px;
}
.buyButton {
  margin-top: 20px;
  padding: 10px 40px;
}
