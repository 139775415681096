.FilterWrapper {
  background-color: #fe1d7f;
  margin-left: -30px;
  margin-right: -30px;
  padding: 16px 30px 0 30px;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #ffffff;
}
.FilterWrapperOpen {
  transition-duration: 0.3s;
}
.FilterWrapperClosed {
  padding-top: 0;
  padding-bottom: 0;
  height: 0;
  transition-duration: 0.3s;
}
.FilterWrapper :global .ant-form-item {
  margin-bottom: 16px;
}
.FilterWrapper :global(.ant-input-affix-wrapper-focused),
.FilterWrapper :global(.ant-input:focus),
.FilterWrapper :global(.ant-input-focused),
.FilterWrapper :global(.ant-picker-focused),
.FilterWrapper :global(.ant-radio-input:focus + .ant-radio-inner),
.FilterWrapper
  :global(.ant-select-focused.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector),
.FilterWrapper
  :global(.ant-select-focused.ant-select-multiple .ant-select-selector) {
  border-color: #ffffff;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.596);
}
.FilterWrapper :global(.ant-form-item-has-error .ant-form-item-explain) {
  color: #ffffff;
}

.FilterWrapper
  :global(.ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before),
.FilterWrapper :global(.ant-radio-checked .ant-radio-inner),
.FilterWrapper :global(.ant-radio-wrapper:hover .ant-radio),
.FilterWrapper :global(.ant-radio:hover .ant-radio-inner),
.FilterWrapper :global(.ant-radio-checked::after),
.FilterWrapper :global(.ant-checkbox-checked::after),
.FilterWrapper :global(.ant-checkbox-checked .ant-checkbox-inner),
.FilterWrapper :global(.ant-checkbox-wrapper:hover .ant-checkbox-inner),
.FilterWrapper :global(.ant-checkbox:hover .ant-checkbox-inner),
.FilterWrapper :global(.ant-checkbox-input:focus + .ant-checkbox-inner),
.FilterWrapper
  :global(.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector:hover),
.FilterWrapper :global(.ant-btn-primary),
.FilterWrapper
  :global(.ant-select:not(.ant-select-disabled):hover .ant-select-selector) {
  border-color: #ffffff;
}

.FilterWrapper
  :global(.ant-picker-cell-in-view.ant-picker-cell-selected
    .ant-picker-cell-inner),
.FilterWrapper
  :global(.ant-picker-cell-in-view.ant-picker-cell-range-start
    .ant-picker-cell-inner),
.FilterWrapper
  :global(.ant-picker-cell-in-view.ant-picker-cell-range-end
    .ant-picker-cell-inner),
.FilterWrapper :global(.ant-radio-inner::after) {
  background-color: #fe1d7f;
}

.FilterWrapper :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: #ffffff;
}

.FilterWrapper :global(.ant-checkbox-checked .ant-checkbox-inner::after) {
  border-color: #fe1d7f;
}
