.AddCustomerContainer {
  height: 67px;
  position: relative;
  margin-bottom: 20px;
}
.AddCustomerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  box-shadow: 0px 21px 37px rgba(106, 28, 28, 0.28);
  z-index: 99;
  border-radius: 10px;
  width: 100%;
  padding: 0 15px;
  overflow: hidden;
}
.LabelColumn {
  padding: 10px 20px;
  background-color: #f8f8f8;
  font-weight: 500;
  color: #171717;
}
.ValueColumn {
  padding: 10px 20px;
}
.AddCustomerWrapper > :global(.ant-row:first-child .ant-col) {
  padding-top: 20px;
}
.AddCustomerWrapper > :global(.ant-row:last-child .ant-col) {
  padding-bottom: 20px;
}
.customerNameInput {
  max-width: 50%;
}
.AddCustomerWrapper :global(.ant-radio-button-wrapper) {
  height: 100%;
  line-height: 1;
  padding: 15px;
  width: 100%;
  border-radius: 5px;
  background-color: #fafafa;
}
.AddCustomerWrapper :global(.ant-radio-button-wrapper:first-child:last-child) {
  border-radius: 5px;
}
.AddCustomerWrapper
  :global(.ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
  background-color: #fe1f7e;
  border-color: #fe1f7e;
}
.AddCustomerWrapper
  :global(.ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before) {
  content: "\f058";
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
  color: #ffffff;
  background-color: transparent;
}
.AddressText {
  font-size: 13px;
}
.AddressType {
  border-radius: 10px;
  background-color: #ffb9d6;
  display: inline-block;
  padding: 2px 7px;
  margin-bottom: 8px;
}

:global(.ant-radio-button-wrapper-checked) .AddressType {
  background-color: #fff !important;
  color: #352a54 !important;
}
.AddCustomerWrapper :global(.ant-radio-button-wrapper:hover) {
  color: inherit;
  border-color: #fe1f7e;
  background-color: #ffecf4;
}
.AddAddress {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  border: 2px dashed #bbbaba;
  border-radius: 5px;
  cursor: pointer;
  color: #bbbaba;
}
.AddAddress > span {
  margin-left: 5px;
}
.ServiceTitle {
  margin-bottom: 8px;
  font-weight: 500;
}
.ButtonContainer {
  text-align: right;
}

.CustomerSelectionWrapper {
  background-color: #352a54;
  padding: 12px 18px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.CustomerSelectionWrapper :global(.ant-input-affix-wrapper-focused),
.CustomerSelectionWrapper :global(.ant-input:focus),
.CustomerSelectionWrapper :global(.ant-input-focused),
.CustomerSelectionWrapper :global(.ant-picker-focused),
.CustomerSelectionWrapper :global(.ant-radio-input:focus + .ant-radio-inner),
.CustomerSelectionWrapper
  :global(.ant-select-focused.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector),
.CustomerSelectionWrapper
  :global(.ant-select-focused.ant-select-multiple .ant-select-selector) {
  border-color: #ffffff;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.596);
}

.CustomerSelectionWrapper
  :global(.ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before),
.CustomerSelectionWrapper :global(.ant-radio-checked .ant-radio-inner),
.CustomerSelectionWrapper :global(.ant-radio-wrapper:hover .ant-radio),
.CustomerSelectionWrapper :global(.ant-radio:hover .ant-radio-inner),
.CustomerSelectionWrapper :global(.ant-radio-checked::after),
.CustomerSelectionWrapper :global(.ant-checkbox-checked::after),
.CustomerSelectionWrapper :global(.ant-checkbox-checked .ant-checkbox-inner),
.CustomerSelectionWrapper
  :global(.ant-checkbox-wrapper:hover .ant-checkbox-inner),
.CustomerSelectionWrapper :global(.ant-checkbox:hover .ant-checkbox-inner),
.CustomerSelectionWrapper
  :global(.ant-checkbox-input:focus + .ant-checkbox-inner),
.CustomerSelectionWrapper
  :global(.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector:hover),
.CustomerSelectionWrapper :global(.ant-btn-primary),
.CustomerSelectionWrapper
  :global(.ant-select:not(.ant-select-disabled):hover .ant-select-selector),
.CustomerSelectionWrapper :global(.ant-input-affix-wrapper:hover),
.CustomerSelectionWrapper :global(.ant-input:hover),
.CustomerSelectionWrapper :global(.ant-picker:hover) {
  border-color: #ffffff;
}
.CustomerSelectionWrapper :global(.ant-input-search-icon::before) {
  display: none;
}
.CustomerSelectionWrapper
  :global(.ant-select-item-option-active:not(.ant-select-item-option-disabled)) {
  background-color: transparent;
}

.AddButton:global(.ant-btn) {
  border-radius: 6px;
  height: 36px;
  width: 36px;
}
.CustomerSelectionOption {
  background-color: #eaeaea;
  border-radius: 5px;
  padding: 11px 17px 20px 17px;
  color: #666666;
}

:global(.ant-select-item-option-active:not(.ant-select-item-option-disabled))
  .CustomerSelectionOption {
  background-color: #fe1d7f;
  color: #ffffff;
}
:global(.ant-select-item-option-active:not(.ant-select-item-option-disabled))
  .CustomerSelectionOption
  .CustomerMembership {
  background-color: #fff127;
  color: #000000;
}
.CustomerSelectionContainer:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.CustomerSelectionOption {
  position: relative;
  z-index: 1;
}
.CustomerNameAndMembership {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
}
.CustomerName {
  font-size: 18px;
  font-weight: bold;
}
.CustomerMembership {
  padding: 2px 14px;
  background-color: #7e13d5;
  color: #ffffff;
  border-radius: 100px;
  font-size: 12px;
}
.CustomerAddressWrapper {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  line-height: 14px;
}
.HomeIcon {
  margin-right: 4px;
}
.CustomerAddress {
  width: 70%;
  white-space: pre-wrap;
}
.CustomerInfoValue {
  color: #ffffff;
  font-size: 13.5px;
  font-weight: 500;
  line-height: 1.1;
}
.CustomerInfoLabel {
  color: #fe1d7f;
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
  line-height: 1.1;
}
.AddressSelectionContainer,
.ServiceSelectionContainer {
  max-width: 330px;
  min-width: 200px;
}
.AddressSelectionOption,
.ServiceSelectionOption {
  background-color: #ebebeb;
  border-radius: 5px;
  padding: 15px;
  position: relative;
  z-index: 1;
  border: 1px solid #ebebeb;
}
.AddressSelectionOption:not(.AddressSelectionOptionSelected):hover,
.ServiceSelectionOption:not(.ServiceSelectionOptionSelected):hover {
  border: 1px solid #fe1d7f;
  background-color: #ffecf4;
}
.AddressSelectionContainer:before,
.ServiceSelectionContainer:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.AddressType,
.AddressText {
  white-space: pre-wrap;
}
.AddressText {
  color: #666666;
}
.AddressType {
  color: #171717;
  padding: 2px 8px;
  border-radius: 19px;
  display: inline-block;
  background-color: #a1d1f5;
  line-height: 1;
}
:global(.ant-dropdown-menu-item:nth-child(3n + 1)) .AddressType {
  background-color: rgb(255, 196, 221);
}

:global(.ant-dropdown-menu-item:nth-child(3n + 2)) .AddressType {
  background-color: #fddb97;
}
.AddressSelectionOptionSelected,
.ServiceSelectionOptionSelected {
  background-color: #fe1d7f;
  position: relative;
}
.AddressSelectionOptionSelected .AddressText,
.ServiceSelectionOptionSelected {
  color: #ffffff;
}
.AddressSelectionOptionSelected:before {
  content: "\f058";
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
  color: #ffffff;
}
:global(.ant-dropdown-menu-item) .AddressSelectionOptionSelected .AddressType {
  background-color: #fff;
  color: #352a54;
}
.DropdownTrigger {
  border-radius: 5px;
  padding: 10px;
  margin: -10px;
  margin-right: 0;
}
.DropdownTrigger:global(.ant-dropdown-open) {
  background-color: #cb0057;
}
.ServiceType {
  font-size: 18px;
  font-weight: 600;
}
.ServiceType,
.ServiceText {
  color: #666666;
}
.ServiceSelectionOptionSelected * {
  color: #fff;
}
.AddOption {
  border: 2px dashed #372a54;
  border-radius: 8px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #372a54;
  font-weight: bold;
  position: relative;
  z-index: 99;
}
.AddOption > span {
  margin-left: 8px;
}
.GarmentBlockContainer {
  display: inline-flex;
  padding: 11.5px;
}

.GarmentBlockWrapper {
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  min-width: 100px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  max-width: 100px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  text-align: center;
}
.GarmentBlockHasValue .GarmentBlockWrapper:hover {
  background-color: #f8f8f8;
  border-color: #7e13d5;
  color: #7e13d5;
}
.GarmentBlockHasValue .GarmentBlockWrapper:hover .GarmentImage {
  filter: none;
  opacity: 1;
}
.GarmentBlockContainer .MinusButton,
.GarmentBlockContainer .PlusButton {
  position: absolute;
  top: 50%;
  height: 20px;
  width: 20px;
  font-size: 12px;
  line-height: 20px;
  padding: 0;
  border-radius: 5px;
  display: none;
  transition-duration: 0.3s;
}
.GarmentBlockHasValue.GarmentBlockContainer
  .GarmentBlockWrapper:hover
  .MinusButton,
.GarmentBlockHasValue.GarmentBlockContainer
  .GarmentBlockWrapper:hover
  .PlusButton {
  transition-duration: 0.3s;
  display: block;
}
.GarmentBlockContainer:not(.GarmentBlockHasValue)
  .GarmentBlockWrapper:hover
  .PlusButton {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  background: rgba(126, 19, 213, 0.78);
  transform: none;
  font-size: 25px;
}
.GarmentBlockContainer:not(.GarmentBlockHasValue)
  .GarmentBlockWrapper:hover
  .PlusButton:hover {
  border-color: #7e13d5;
}
.MinusButton {
  left: 0;
  transform: translate(-50%, -50%);
}
.PlusButton {
  right: 0;
  transform: translate(50%, -50%);
}
.GarmentImage {
  max-width: 50px;
  height: auto;
  margin-bottom: 10px;
  filter: grayscale(1);
  opacity: 0.8;
}

.GarmentBlockContainer :global(.ant-badge-count) {
  margin-left: 5px;
  background: #fe1d62;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.GarmentSelectionCollapse {
  background-color: transparent;
}
.GarmentSelectionCollapse:global(.ant-collapse-borderless
    .ant-collapse-item:not(.ant-collapse-item-active)) {
  background-color: #f8f8f8;
  border: 1px solid #d5d5d5;
}

.GarmentSelectionCollapse:global(.ant-collapse-borderless
    > .ant-collapse-item) {
  border: 1px solid #ffffff;
  border-radius: 10px;
  background: #fff;
  /* box-shadow: 0px 0px 99px rgba(38, 27, 59, 0.50); */
  margin-bottom: 15px;
  border: 1px solid #e3e6e9;
}
.GarmentSelectionCollapse > :global(.ant-collapse-item > .ant-collapse-header) {
  padding: 22px;
  padding-bottom: 0;
  color: #171717;
  font-weight: bold;
}
.PanelSearch :global(.ant-input-search-icon::before) {
  border: none;
}
.PanelSearch:global(.ant-input-affix-wrapper) {
  padding: 3px 8px;
}
.PanelSearch :global(.ant-input-search-icon) {
  padding: 0;
}
.Extras {
  display: flex;
  align-items: stretch;
}
.Extras :global(.ant-select),
.Extras
  :global(.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
    .ant-select-selector) {
  height: 100%;
}
.Extras
  :global(.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow
    .ant-select-selection-item) {
  line-height: 28px;
}
:global(.ant-collapse-item:not(.ant-collapse-item-active)) .PanelSearchWrapper {
  opacity: 0;
  display: none;
}

:global(.ant-collapse-item:not(.ant-collapse-item-active)
    > .ant-collapse-header) {
  padding: 10px 22px;
}

:global(.ant-collapse-item.ant-collapse-item-active) .PanelToggleIconWrapper {
  display: none;
}
.PanelHeader {
  display: inline-flex;
  align-items: center;
}
.PanelHeader > :global(.ant-badge .ant-badge-count) {
  margin-left: 5px;
  background: #fe1d62;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.GarmentsContainer {
  margin-left: -7px;
  margin-right: -7px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.OrderSummaryContainer {
  background-color: #ffffff;
  border-radius: 5px 0px 0px 5px;
  padding: 20px;
  width: calc(100% + 25px);
  margin-right: -25px;
}
.OrderTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  position: relative;
  padding-bottom: 7px;
  margin-bottom: 7px;
}
.OrderTitle:after,
.SummaryTableWrapper:after {
  content: "";
  width: calc(100% + 40px);
  height: 2px;
  border-bottom: 1px dashed #b8b8b8;
  position: absolute;
  bottom: 0;
  left: -20px;
}
.SummaryTable {
  width: 100%;
}
.SummaryTableWrapper {
  position: relative;
  padding-bottom: 35px;
}
.ItemRow > td {
  background-color: #fafafa;
  border-bottom: 1px solid #eeeeee;
}
.CategoryLabel {
  font-size: 13px;
  font-weight: bold;
  padding: 2px 9px;
  background-color: #ff8eb1;
  display: inline-block;
  border-radius: 5px;
  color: #ffffff;
  margin-top: 15px;
}
.PromocodeInput {
  margin-top: 30px;
  margin-bottom: 20px;
}
.PromocodeInput:global(.ant-input-affix-wrapper-focused),
.PromocodeInput:global(.ant-input-affix-wrapper:hover) {
  border-color: #fe1d7f;
}
.PromocodeInput:global(.ant-input-affix-wrapper-focused) {
  box-shadow: 0 0 0 2px rgba(254, 29, 126, 0.18);
}
.PromocodeInput:global(.ant-input-affix-wrapper) {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
}
.PromocodeInput :global(.ant-input-suffix .ant-btn) {
  border-radius: 4px;
}
.PromocodeInput :global(.ant-input-prefix) {
  color: #fe1d7f;
}
.ItemSettingIcon {
  color: #c6c6c6;
  margin-left: 10px;
  border: none;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  cursor: pointer;
}
.ItemSettingIcon:hover,
.ItemSettingIcon:focus {
  outline: none;
  color: #666;
}
.SummaryInfoWrap {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  color: #707070;
}
.SummaryInfoLabel,
.SummaryInfoLabel > .InfoIcon {
  display: flex;
  align-items: center;
}
.SummaryInfoLabel > .InfoIcon img {
  margin-left: 5px;
  width: 14px;
  cursor: pointer;
}
.SummaryInfoValue {
  white-space: nowrap;
}
.SummaryInfoSubPart .SummaryInfoWrap {
  color: #ababab;
}
.TotalBlock {
  background-color: #352a54;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  font-weight: bold;
  padding: 8px 20px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.ReviewOrderButton {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  padding: 10px 26px;
  margin-bottom: 30px;
}
.ReviewOrderButton > div {
  display: flex;
  justify-content: space-between;
}

/**
    Styling for Invoice
    @added 11 June 2020
    */
.SummaryContainerInvoice {
  margin-top: 10px;
  padding: 0;
  width: 100%;
}
.SummaryTableInvoice > table {
  border: 1px solid #eeeeee;
}
.SummaryTableInvoice > table td,
.SummaryTableInvoice > table th {
  padding: 3px 14px;
}
.SummaryTableInvoice > table th {
  background-color: #fafafa;
  border-bottom: 1px solid #eeeeee;
}
.SummaryTableInvoice .CategoryLabel {
  margin-top: 0;
}
.SummaryTableInvoice:after {
  display: none;
}

.ReviewOrderSummary :global(.ant-descriptions-item) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.TableFormItemWrap :global(.ant-form-item) {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.TableFormItemWrap
  :global(.ant-form-item-label > label.ant-form-item-required::before) {
  margin-left: 0px;
}
.TableFormItemWrap :global(.ant-form-item-label) {
  margin-right: 5px;
}
:global(.ant-form-inline) td :global(.ant-form-item-with-help) {
  margin-bottom: 0;
}

/* .hr {
    clear:both;
    margin-bottom: 0px;
    border: 0;
    height: 3px;
    background-image: linear-gradient(to right, white, transparent, white), linear-gradient(transparent, rgba(0, 0, 0, 0.55) 150%);    
    background-image: -moz-linear-gradient(to right, white, transparent, white), -webkit-linear-gradient(transparent, rgba(0,0,0,0.55) 150%);
    background-image: -ms-linear-gradient(to right, white, transparent, white), -webkit-linear-gradient(transparent, rgba(0,0,0,0.55) 150%);
  } */

.hr {
  border-bottom: 1px solid #e3e6e9;
}
.TableFields :global(.ant-form-item) {
  margin-bottom: 0;
}

